import { useEffect, useState } from "react";
import { getNuestrosTrabajos } from "../api";

export default function NuestrosTrabajos() {
    const [nuestrosTrabajos, setNuestrosTrabajos] = useState([]);
    const [activeFilter, setActiveFilter] = useState(0);

    useEffect(() => {
        const fetchNuestrosTrabajosData = async () => {
            const nuestrosTrabajosResponse = await getNuestrosTrabajos();
            setNuestrosTrabajos(nuestrosTrabajosResponse);
        };
        fetchNuestrosTrabajosData();
    }, []);

    function handleFilter(id) {
        setActiveFilter(id);
    }

    return (
        <div className="trabajos-container">
            <div className="nuestros-trabajos-header">
                <h1>Nuestros Trabajos</h1>
                <hr></hr>
                <p className="text-lg">
                    Bienvenido a nuestra galería de trabajos, donde la maestría
                    en carpintería metálica y PVC cobra vida. En cada proyecto,
                    fusionamos la precisión técnica con la creatividad para
                    ofrecer soluciones innovadoras y a medida. Explora nuestra
                    colección de imágenes que narran historias de transformación
                    arquitectónica y calidad artesanal. Cada imagen cuenta la
                    historia de un desafío superado, un diseño realizado con
                    destreza y la satisfacción de clientes particulares y
                    empresas que confiaron en nosotros para sus necesidades de
                    reforma y construcción. Sumérgete en la excelencia que
                    define a nuestro equipo de profesionales en cada detalle.
                </p>
            </div>
            <ul className="products-nav">
                <li
                    className={activeFilter === 0 ? "active" : "in-active"}
                    key={0}
                    onClick={() => handleFilter(0)}
                >
                    Todos
                </li>
                {nuestrosTrabajos.map((trabajo) => (
                    <li
                        className={
                            activeFilter === trabajo.id ? "active" : "in-active"
                        }
                        key={trabajo.id}
                        onClick={() => handleFilter(trabajo.id)}
                    >
                        {trabajo.category}
                    </li>
                ))}
            </ul>
            <ul className="products-items">
                {activeFilter === 0
                    ? nuestrosTrabajos.map((trabajo) =>
                          trabajo.examples.map((example) => (
                              <div key={example.id} className="shadow-md p-4">
                                  <img
                                      src={example.imgSource}
                                      alt={example.name}
                                  ></img>
                                  <span>{example.name}</span>
                              </div>
                          ))
                      )
                    : nuestrosTrabajos
                          .find((trabajo) => trabajo.id === activeFilter)
                          .examples.map((example) => (
                              <div key={example.id} className="shadow-md p-4">
                                  <img
                                      src={example.imgSource}
                                      alt={example.name}
                                  ></img>
                                  <span>{example.name}</span>
                              </div>
                          ))}
                {}
            </ul>
        </div>
    );
}
