import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../utils/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBriefcase,
    faBuilding,
    faEye,
    faList,
    faPowerOff,
} from "@fortawesome/free-solid-svg-icons";
import { cookies } from "../../constants";
import logo_expansion_alumet from "../../../Assets/Images/logo_expansion_alumet.png";
import { SERVER_URL } from "../../api";
import { toast } from "react-toastify";

export default function Menu() {
    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        if (state?.updateSuccessful) {
            toast.success(state.message, {
                position: "top-center",
            });
        }
    }, [state]);

    return (
        <>
            <div className="w-full flex border-b-2 border-logo text-gray-800 justify-between items-center py-4 px-8">
                <div className="flex items-center gap-4">
                    <img
                        src={logo_expansion_alumet}
                        alt="Logo Expansion alumet"
                        width={180}
                    />
                    <h1 className="text-center">Panel de Administracion</h1>
                </div>
                <a href={SERVER_URL} target="_blank" rel="noreferrer">
                    <Button
                        text={"Visualizar Página Web"}
                        className={
                            "px-8 py-2 flex items-center gap-2 cursor-pointer border-white border text-white bg-primary rounded text-lg"
                        }
                        icon={<FontAwesomeIcon icon={faEye} />}
                    ></Button>
                </a>
            </div>
            <div className="flex mt-24 justify-center items-center">
                <div className="flex justify-center items-center flex-col gap-10 p-10 shadow-lg rounded-md">
                    <h2 className="text-2xl">Men&uacute;</h2>
                    <div className="w-56 flex flex-col gap-10">
                        <Button
                            onClick={() => navigate("/admin/productos")}
                            text={"Productos"}
                            icon={<FontAwesomeIcon icon={faList} />}
                            className={
                                "w-full px-6 py-2 flex items-center gap-2 cursor-pointer border-white border text-white bg-primary rounded text-lg"
                            }
                        ></Button>
                        <Button
                            onClick={() => navigate("/admin/nuestrostrabajos")}
                            icon={<FontAwesomeIcon icon={faBriefcase} />}
                            text={"Nuestros Trabajos"}
                            className={
                                "w-full px-6 flex items-center gap-2 py-2 cursor-pointer border-white border text-white bg-primary rounded text-lg"
                            }
                        ></Button>
                        <Button
                            onClick={() => navigate("/admin/datosdeempresa")}
                            text={"Datos de Empresa"}
                            icon={<FontAwesomeIcon icon={faBuilding} />}
                            className={
                                "w-full px-6 py-2 flex items-center gap-2 cursor-pointer border-white border text-white bg-primary rounded text-lg"
                            }
                        ></Button>
                        <Button
                            onClick={() => {
                                cookies.remove("JWT_ACCESS_COOKIE_NAME");
                                navigate("/admin");
                            }}
                            text={"Cerrar Sesión"}
                            className={
                                "px-6 py-2 flex items-center gap-2 cursor-pointer border-white border text-white bg-primary rounded text-lg"
                            }
                            icon={<FontAwesomeIcon icon={faPowerOff} />}
                        ></Button>
                    </div>
                </div>
            </div>
        </>
    );
}
