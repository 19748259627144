import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../utils/Button";
import { useNavigate } from "react-router-dom";
import { cookies } from "../../constants";
import logo_expansion_alumet from "../../../Assets/Images/logo_expansion_alumet.png";
import { iniciarSesionAdmin, validateSession } from "../../api/admin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket, faUserTie } from "@fortawesome/free-solid-svg-icons";

export default function Login() {
    const [errorMessage, setErrorMessage] = useState("");
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const validate = async () => {
            const isValidSession = await validateSession(navigate);

            if (isValidSession) {
                navigate("/admin/menu");
            }
        };
        validate();
    }, [navigate]);

    async function onSubmit(data) {
        setErrorMessage("");

        const res = await iniciarSesionAdmin(data);

        if (res.accessToken === undefined) {
            setErrorMessage("Credenciales Incorrectos.");
            reset();
        } else {
            cookies.set("JWT_ACCESS_COOKIE_NAME", res.accessToken);
            navigate("/admin/menu");
        }
    }

    return (
        <main>
            <div className="flex mt-32 justify-center items-center">
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex justify-center items-center flex-col gap-8 p-10 shadow-md bg-white"
                >
                    <img
                        src={logo_expansion_alumet}
                        alt="Logo Expansion alumet"
                        width={300}
                    />
                    <h2 className="text-gray-800 text-2xl flex items-center gap-4">
                        <FontAwesomeIcon icon={faUserTie} />
                        Panel de Administración
                    </h2>
                    {errorMessage && (
                        <p className="-mt-4 -mb-4 text-red-500 font-bold self-start">
                            {errorMessage}
                        </p>
                    )}
                    <div className="flex flex-col gap-1 w-full">
                        <label
                            className="text-lg font-bold"
                            htmlFor="usuario-input"
                        >
                            Usuario
                        </label>
                        <input
                            id="usuario-input"
                            className="w-full px-3 py-1 focus:ring-1 focus:ring-inputfocus focus:outline-none rounded-sm border-gray-300 border"
                            type="text"
                            placeholder="Tu usuario..."
                            name="username"
                            {...register("username", { required: true })}
                        />
                        {errors?.username?.type === "required" && (
                            <span className="text-red-500">
                                Este campo es obligatorio.
                            </span>
                        )}
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <label
                            className="text-lg font-bold"
                            htmlFor="password-input"
                        >
                            Contraseña
                        </label>
                        <input
                            id="password-input"
                            className="w-full px-3 py-1 focus:ring-1 focus:ring-inputfocus focus:outline-none rounded-sm border-gray-300 border"
                            type="password"
                            name="password"
                            placeholder="Tu contraseña..."
                            {...register("password", { required: true })}
                        />
                        {errors?.password?.type === "required" && (
                            <span className="text-red-500">
                                Este campo es obligatorio.
                            </span>
                        )}
                    </div>
                    <Button
                        text={"Iniciar Sesión"}
                        type="submit"
                        icon={<FontAwesomeIcon icon={faRightToBracket} />}
                        className={
                            "w-full px-6 flex items-center justify-center gap-2 py-2 cursor-pointer border-white border text-white bg-primary rounded text-xl"
                        }
                    ></Button>
                </form>
            </div>
        </main>
    );
}
