import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Spinner = ({ text }) => {
    return (
        <div className="text-2xl flex items-center justify-center gap-2">
            <FontAwesomeIcon className="animate-spin" icon={faSpinner} /> {text}
        </div>
    );
};

export default Spinner;
