import menu from "../../Assets/Icons/icons8-menu-50.png";
import Navbar from "../Navbar/Navbar";
import { useContext, useState } from "react";

import logo_expansion_alumet from "../../Assets/Images/logo_expansion_alumet.png";
import logo_expansion_alumet_white from "../../Assets/Images/logo_expansion_alumet_white.png";

import catalog from "../../Assets/Icons/icons8-catalog-50.png";
import { Link } from "react-router-dom";
import Button from "../utils/Button";
import { CompanyDetailsContext } from "../../context/companyDetails";

export default function Header() {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const companyDetails = useContext(CompanyDetailsContext);

    function handleIsNavOpen() {
        setIsNavOpen(!isNavOpen);
    }

    return (
        <>
            <div className="header-nav">
                <header className="header-container">
                    <img
                        src={menu}
                        alt="icono de menu"
                        className="menu-icon"
                        onClick={handleIsNavOpen}
                    ></img>
                    <Link to={"/"}>
                        <img
                            src={logo_expansion_alumet_white}
                            alt="logo"
                            width={220}
                        ></img>
                    </Link>
                </header>
                {isNavOpen && <Navbar handleNavOpen={handleIsNavOpen}></Navbar>}
            </div>

            {/*Dektop Version*/}

            <div className="header-nav-desktop">
                <header className="header-desktop">
                    <div className="main-header-desktop">
                        <Link to="/">
                            <img
                                src={logo_expansion_alumet}
                                alt="logo"
                                width={220}
                                className="header-title-desktop"
                            ></img>
                        </Link>
                        <div className="header-btton-div-desktop">
                            <a
                                href={companyDetails?.catalogueUrl}
                                alt="Ver Catalogo de Expansion Alumet SL"
                                className="flex items-center gap-2"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={catalog}
                                    alt="catalog icon"
                                    className="catalog-icon-desktop"
                                ></img>
                                <Button
                                    text={"NUESTRO CATÁLOGO"}
                                    className=""
                                ></Button>
                            </a>
                        </div>
                    </div>
                    <Navbar></Navbar>
                </header>
            </div>
        </>
    );
}
