import { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../utils/Button";

import placeholderImagen from "../../../Assets/Images/PlaceholderImagen.png";
import { isEmpty } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FormsContext } from "../../../context/forms";

export default function CategoryExampleForm({
    ejemploProducto,
    categoryId,
    handleProductosAEliminar,
    transitionProductAsNotNew,
    transitionProductToBeUpdated,
    setSubmitRefList,
    submitRefList,
    cleanEjemploProducto,
    crearEjemplo,
    actualizarEjemplo,
    eliminarEjemplo,
}) {
    const [imagenPreview, setImagenPreview] = useState(
        ejemploProducto.imgSource
    );
    const [mensajeErrorImagen, setMensajeErrorImagen] = useState("");
    const [isDeletedFromServer, setIsDeletedFromServer] = useState(false);

    const [willBeDeleted, setWillBeDeleted] = useState(false);

    const inputSubmitRef = useRef(false);

    const { register, handleSubmit, formState, setError, clearErrors } =
        useForm({
            mode: "onBlur",
        });

    useEffect(() => {
        if (!isEmpty(formState.dirtyFields) && ejemploProducto.isNew) {
            transitionProductAsNotNew(ejemploProducto.id);
        }
    }, [
        formState.dirtyFields,
        ejemploProducto.id,
        ejemploProducto.isNew,
        transitionProductAsNotNew,
    ]);

    useEffect(() => {
        if (!isEmpty(formState.dirtyFields) && !ejemploProducto.toBeUpdated) {
            transitionProductToBeUpdated(ejemploProducto.id);
        }
    }, [
        formState,
        transitionProductToBeUpdated,
        ejemploProducto.id,
        ejemploProducto.toBeUpdated,
    ]);

    const formsContext = useContext(FormsContext);

    useEffect(() => {
        if (willBeDeleted) {
            return;
        }

        if (!isEmpty(formState.errors)) {
            formsContext.updateForm(ejemploProducto.id, "INVALID");
        } else if (
            isEmpty(formState.errors) &&
            !isEmpty(formState.dirtyFields)
        ) {
            formsContext.updateForm(ejemploProducto.id, "VALID");
        }
    }, [formState, formsContext, ejemploProducto.id, willBeDeleted]);

    useEffect(() => {
        if (formsContext.isAlreadyInList(ejemploProducto.id)) {
            return;
        }
        formsContext.addForm(
            ejemploProducto.id,
            !ejemploProducto.isNew ? "VALID" : "INVALID"
        );
        if (ejemploProducto.isNew) {
            setError("name", { type: "initial", message: "custom start" });
            if (window.location.pathname.includes("/productos")) {
                setError("description", {
                    type: "initial",
                    message: "custom start",
                });
            }
            setError("fileImage", {
                type: "required",
                message: "image required on new products",
            });
        }
    }, [formsContext, ejemploProducto.id, ejemploProducto.isNew, setError]);

    useEffect(() => {
        if (inputSubmitRef && submitRefList) {
            const shouldAddNewInputRef = submitRefList.filter(
                (input) => input.id === ejemploProducto.id
            );

            if (shouldAddNewInputRef.length > 0) {
                return;
            }

            const newInputRef = {
                id: ejemploProducto.id,
                input: inputSubmitRef,
            };
            setSubmitRefList([...submitRefList, newInputRef]);
        }
    }, [inputSubmitRef, setSubmitRefList, submitRefList, ejemploProducto.id]);

    function handleImageUpload(e) {
        const reader = new FileReader();

        reader.onloadend = () => {
            setMensajeErrorImagen("");
            clearErrors("fileImage");
            setImagenPreview(reader.result);
        };

        e.target.files.length > 0 && reader.readAsDataURL(e.target.files[0]);
    }

    async function onSubmit(data) {
        setMensajeErrorImagen("");

        if (ejemploProducto.toBeCreated && !ejemploProducto.toBeDeleted) {
            if (data.fileImage.length <= 0) {
                setMensajeErrorImagen("Este campo es obligatorio.");
                return;
            }

            const response = await crearEjemplo(
                categoryId,
                data.name,
                data.fileImage,
                data.description
            );
            console.log(response);
        } else if (ejemploProducto.toBeUpdated) {
            const response = await actualizarEjemplo(
                categoryId,
                ejemploProducto.id,
                data.name,
                data.description,
                data.fileImage
            );
            console.log(response);
        } else if (ejemploProducto.toBeDeleted) {
            const response = await eliminarEjemplo(
                categoryId,
                ejemploProducto.id
            );
            cleanEjemploProducto(ejemploProducto.id);
            setIsDeletedFromServer(true);
            console.log(response);
        }
    }

    if (isDeletedFromServer) {
        return <div></div>;
    }

    return (
        <form
            className="relative"
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
                if (e.code === "Enter") {
                    e.preventDefault();
                }
            }}
        >
            {ejemploProducto.toBeDeleted && (
                <>
                    <span className="absolute text-red-600 font-bold text-md top-1/4 w-full text-center left-1/2 transform -translate-x-1/2 -translate-y-1/2s">
                        Esto será eliminado al guardar los cambios.
                    </span>
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2s">
                        <Button
                            text={"Deshacer"}
                            icon={<FontAwesomeIcon icon={faUndo} />}
                            onClick={() => {
                                setWillBeDeleted(false);
                                handleProductosAEliminar(ejemploProducto.id);
                                formsContext.updateForm(
                                    ejemploProducto.id,
                                    !isEmpty(formState.errors)
                                        ? "INVALID"
                                        : "VALID"
                                );
                            }}
                            className="px-6 py-2 items-center flex gap-2 cursor-pointer border-white border text-white bg-primary rounded-md text-lg"
                        />
                    </div>
                </>
            )}
            <div
                className={
                    !ejemploProducto.toBeDeleted
                        ? "flex flex-col justify-center items-center gap-6 border p-4 border-black relative"
                        : "flex flex-col justify-center items-center gap-6 border p-4 border-black relative opacity-10 -z-10"
                }
            >
                <div className="flex flex-col justify-center items-center gap-3">
                    <img
                        src={
                            Boolean(imagenPreview)
                                ? imagenPreview
                                : placeholderImagen
                        }
                        alt="imagen-producto"
                        className="w-56 object-fill aspect-square rounded-md"
                    />

                    <label
                        htmlFor={`input-imagen-${ejemploProducto.id}`}
                        className="w-full flex justify-around items-center px-1 py-2 bg-white text-blue rounded-lg shadow-sm tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white"
                    >
                        <svg
                            className="w-6 h-6"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                        </svg>
                        <span className="text-gray-400">Subir otra imagen</span>
                        <input
                            onInput={handleImageUpload}
                            id={`input-imagen-${ejemploProducto.id}`}
                            type="file"
                            className="hidden"
                            {...register("fileImage")}
                            accept=".png, .jpeg, .jpg"
                        />
                    </label>
                    {mensajeErrorImagen && (
                        <span className="font-bold text-red-700">
                            Imagen obligatoria
                        </span>
                    )}
                </div>
                <div className="flex flex-col justify-center w-full">
                    <label
                        htmlFor={`input-name-${ejemploProducto.id}`}
                        className="font-bold"
                    >
                        Nombre
                    </label>
                    <input
                        placeholder="Escribe un nombre aqui"
                        id={`input-name-${ejemploProducto.id}`}
                        className=" p-1 focus:ring-1 focus:ring-inputfocus focus:outline-none rounded-sm border-gray-600 border"
                        {...register("name", { required: true })}
                        defaultValue={ejemploProducto.name}
                    />
                    {formState.errors?.name?.type === "required" && (
                        <span className="text-red-500">
                            Este campo es obligatorio.
                        </span>
                    )}
                </div>
                {window.location.pathname.includes("/productos") && (
                    <div className="flex flex-col justify-center">
                        <label
                            htmlFor={`input-descripcion-${ejemploProducto.id}`}
                            className="font-bold"
                        >
                            Descripcion
                        </label>
                        <textarea
                            rows={5}
                            cols={24}
                            id={`input-descripcion-${ejemploProducto.id}`}
                            className=" p-1 focus:ring-1 focus:ring-inputfocus focus:outline-none rounded-sm border-gray-600 border resize-none"
                            defaultValue={ejemploProducto.description}
                            {...register("description", {
                                required: true,
                            })}
                            placeholder="Escribe descipcion de producto"
                        ></textarea>
                        {formState.errors?.description?.type === "required" && (
                            <span className="text-red-500">
                                Este campo es obligatorio.
                            </span>
                        )}
                    </div>
                )}
                <div className="w-full flex items-center justify-center">
                    <Button
                        onClick={() => {
                            handleProductosAEliminar(ejemploProducto.id);
                            setWillBeDeleted(true);

                            if (!ejemploProducto.isNew) {
                                formsContext.updateForm(
                                    ejemploProducto.id,
                                    "VALID"
                                );
                            }
                        }}
                        text={"Eliminar"}
                        icon={<FontAwesomeIcon icon={faTrash} />}
                        className="px-6 py-2 items-center flex gap-2 cursor-pointer border-white border text-white bg-primary rounded-md text-lg"
                    ></Button>
                </div>
            </div>
            <input type="submit" ref={inputSubmitRef} className="hidden" />
        </form>
    );
}
