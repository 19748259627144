import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { validateSession } from "../../api/admin";

export default function WithAuth({ Component }) {
    const [isAuth, setIsAuth] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const validate = async () => {
            const isValidSession = await validateSession(navigate);

            if (!isValidSession) {
                navigate("/admin");
            }

            return isValidSession;
        };

        setIsAuth(validate());
    }, [navigate]);

    if (!isAuth) {
        return <div>Cargando...</div>;
    }

    return <Component />;
}
