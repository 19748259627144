import logo_expansion_alumet_white from "../../Assets/Images/logo_expansion_alumet_white.png";

import Button from "../utils/Button";

import { Link } from "react-router-dom";
import { useContext } from "react";
import { CompanyDetailsContext } from "../../context/companyDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebookSquare,
    faInstagram,
    faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {
    faEnvelope,
    faLocationDot,
    faPhone,
} from "@fortawesome/free-solid-svg-icons";

export default function Footer() {
    const companyDetails = useContext(CompanyDetailsContext);

    return (
        <footer className="footer-container">
            <hr></hr>
            <div className="text-logo-footer">
                <img
                    src={logo_expansion_alumet_white}
                    alt="logo"
                    width={90}
                    className="header-title-desktop"
                ></img>
                <p>{companyDetails?.description}</p>
                <div className="socials-footer">
                    <span>Siguenos: </span>
                    <a
                        href={companyDetails?.facebookUrl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FontAwesomeIcon
                            icon={faFacebookSquare}
                            className="text-2xl"
                        />
                    </a>
                    <a
                        href={companyDetails?.instagramUrl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FontAwesomeIcon
                            icon={faInstagram}
                            className="text-2xl"
                        />
                    </a>
                    <a
                        href={companyDetails?.whatsappUrl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FontAwesomeIcon
                            icon={faWhatsapp}
                            className="text-2xl"
                        />
                    </a>
                </div>
            </div>
            <div className="contacto-footer">
                <h4>CONTACTO</h4>
                <div>
                    <a
                        href="https://maps.app.goo.gl/1SVJwvT6im3CfCU26"
                        target="_blank"
                        rel="noreferrer"
                        className="flex items-center gap-5"
                    >
                        <FontAwesomeIcon
                            icon={faLocationDot}
                            className="text-2xl cursor-pointer  hover:text-primary"
                        />

                        <span>{companyDetails?.address}</span>
                    </a>
                </div>
                <div>
                    <FontAwesomeIcon
                        icon={faPhone}
                        className="text-2xl cursor-pointer  hover:text-primary"
                    />
                    <span>{companyDetails?.phone}</span>
                </div>
                <div>
                    <a href={`mailto:${companyDetails?.email}`}>
                        <FontAwesomeIcon
                            icon={faEnvelope}
                            className="text-2xl"
                        />
                    </a>
                    <span>{companyDetails?.email}</span>
                </div>
            </div>
            <div className="presupuesto-footer">
                <h4>PRESUPUESTO</h4>
                <p>Solicita un presupuesto sin ningún compromiso.</p>
                <Link to={"/presupuestos"}>
                    <Button text={"Solicitar Presupuesto"} />
                </Link>
            </div>
            <hr></hr>
        </footer>
    );
}
