import axios from "axios";
import { calculateServerUrlByHostname } from "./helpers";

export const SERVER_URL = calculateServerUrlByHostname();

export default async function getProducts() {
    try {
        const response = await axios.get(`${SERVER_URL}api/v1/products`);
        const { items = [] } = response?.data || {};
        return items.filter((item) => item.products.length > 0);
    } catch {
        return null;
    }
}

export async function getNuestrosTrabajos() {
    try {
        const response = await axios.get(
            `${SERVER_URL}api/v1/nuestros-trabajos`
        );
        const { works = [] } = response?.data || {};
        return works.filter((work) => work.examples.length > 0);
    } catch (error) {
        return null;
    }
}

export async function getCompanyDetails() {
    try {
        const response = await axios.get(`${SERVER_URL}api/v1/company/details`);
        return response?.data || {};
    } catch (error) {
        return {};
    }
}

export async function enviarCorreoPresupuesto(data) {
    try {
        const response = await axios.post(
            `${SERVER_URL}api/v1/presupuesto`,
            data
        );
        return response?.data?.message || {};
    } catch (error) {
        return {};
    }
}
