import { useNavigate } from "react-router-dom";
import AdminNav from "../../utils/AdminNav";
import { useForm } from "react-hook-form";
import { useContext, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { CompanyDetailsContext } from "../../../context/companyDetails";
import Button from "../../utils/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faCheck,
    faFloppyDisk,
    faTriangleExclamation,
    faX,
} from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "../../utils";
import { actualizarDatosDeEmpresa } from "../../api/admin";
import { toast } from "react-toastify";

export default function DatosDeEmpresaAdmin() {
    const [showModal, setShowModal] = useState(false);
    const [imagenPreview, setImagenPreview] = useState();
    const [mensajeErrorImagen, setMensajeErrorImagen] = useState("");

    const navigate = useNavigate();

    const companyDetails = useContext(CompanyDetailsContext);

    useEffect(() => {
        setImagenPreview(companyDetails.heroImageUrl);
    }, [companyDetails.heroImageUrl]);

    const {
        register,
        formState: { errors },
        handleSubmit,
        watch,
        clearErrors,
    } = useForm({
        mode: "onBlur",
    });

    const catalogueFile = watch("fileCatalogue");
    const submitBttnRef = useRef();

    async function onSubmit(data) {
        const response = await actualizarDatosDeEmpresa(data);

        console.log(response);

        if (Boolean(response?.message) || !Boolean(response)) {
            toast.error(
                "Ha ocurrido un error, vuelve a intentarlo mas tarde.",
                {
                    position: "top-center",
                }
            );

            setShowModal(false);

            return;
        }

        companyDetails.setCompanyDetails({ ...companyDetails, ...response });

        setShowModal(!showModal);
        navigate("/admin/menu", {
            state: {
                updateSuccessful: true,
                message: "Los cambios se han actualizado correctamente.",
            },
        });
    }

    function handleShowModal() {
        if (!isEmpty(errors)) {
            return;
        }

        setShowModal(!showModal);
    }

    Modal.setAppElement("#root");

    function handleImageUpload(e) {
        const reader = new FileReader();

        reader.onloadend = () => {
            setMensajeErrorImagen("");
            clearErrors("fileImage");
            setImagenPreview(reader.result);
        };

        e.target.files.length > 0 && reader.readAsDataURL(e.target.files[0]);
    }

    return (
        <>
            <AdminNav navigate={navigate}></AdminNav>
            <div className="flex justify-center items-center mt-10 ">
                <form
                    className="flex flex-col gap-4 text-lg"
                    onSubmit={handleSubmit(onSubmit)}
                    onKeyDown={(e) => {
                        if (e.code === "Enter") {
                            e.preventDefault();
                        }
                    }}
                >
                    <p className="text-xl font-bold">Datos de Empresa</p>
                    <div className="grid gap-20 grid-cols-[minmax(350px,_1fr)_minmax(300px,_1fr)_minmax(300px,_1fr)]">
                        <div className="flex flex-col row-span-2">
                            <label htmlFor="" className="font-bold">
                                Descripci&oacute;n
                            </label>
                            <textarea
                                className="resize-none px-3 py-2 focus:ring-1 focus:ring-inputfocus focus:outline-none rounded-sm border-gray-600 border"
                                rows={6}
                                placeholder="Introduce un enlace"
                                type="text"
                                defaultValue={companyDetails?.description}
                                {...register("description", {
                                    required: true,
                                })}
                            ></textarea>
                            {errors?.description?.type === "required" && (
                                <span className="text-red-500">
                                    Este campo es obligatorio.
                                </span>
                            )}
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="" className="font-bold">
                                Correo
                            </label>
                            <input
                                className="px-3 py-2 focus:ring-1 focus:ring-inputfocus focus:outline-none rounded-sm border-gray-600 border"
                                placeholder="Introduce un enlace"
                                type="text"
                                defaultValue={companyDetails?.email}
                                {...register("email", {
                                    required: true,
                                })}
                            />
                            {errors?.email?.type === "required" && (
                                <span className="text-red-500">
                                    Este campo es obligatorio.
                                </span>
                            )}
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="" className="font-bold">
                                Telefono
                            </label>
                            <input
                                className="px-3 py-2 focus:ring-1 focus:ring-inputfocus focus:outline-none rounded-sm border-gray-600 border"
                                placeholder="Introduce un enlace"
                                type="text"
                                defaultValue={companyDetails?.phone}
                                {...register("phone", {
                                    required: true,
                                })}
                            />
                            {errors?.phone?.type === "required" && (
                                <span className="text-red-500">
                                    Este campo es obligatorio.
                                </span>
                            )}
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="" className="font-bold">
                                Direcci&oacute;n
                            </label>
                            <input
                                className="px-3 py-2 focus:ring-1 focus:ring-inputfocus focus:outline-none rounded-sm border-gray-600 border"
                                placeholder="Introduce un enlace"
                                type="text"
                                defaultValue={companyDetails?.address}
                                {...register("address", {
                                    required: true,
                                })}
                            />
                            {errors?.address?.type === "required" && (
                                <span className="text-red-500">
                                    Este campo es obligatorio.
                                </span>
                            )}
                        </div>
                        <label
                            htmlFor="input-catalogo-categoria"
                            className="w-64 flex flex-col items-center px-6 py-6 bg-white text-blue rounded-lg shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white"
                        >
                            <svg
                                className="w-8 h-8"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                            </svg>
                            <span className="mt-2 text-base leading-normal">
                                {catalogueFile?.length > 0
                                    ? `${catalogueFile[0].name.slice(
                                          0,
                                          10
                                      )}..${catalogueFile[0].name.slice(
                                          catalogueFile[0].name.length - 4,
                                          catalogueFile[0].name.length
                                      )}`
                                    : "Sube un catalogo"}
                            </span>
                            <input
                                id="input-catalogo-categoria"
                                type="file"
                                className="hidden"
                                {...register("fileCatalogue")}
                                accept=".doc, .docx, .pdf"
                            />
                        </label>
                    </div>
                    <p className="font-bold text-xl">Redes Sociales</p>
                    <div className="grid grid-cols-3 gap-10">
                        <div className="flex flex-col">
                            <label htmlFor="" className="font-bold">
                                Facebook
                            </label>
                            <input
                                className="px-3 py-2 focus:ring-1 focus:ring-inputfocus focus:outline-none rounded-sm border-gray-600 border"
                                type="text"
                                placeholder="Introduce un enlace"
                                defaultValue={companyDetails?.facebookUrl}
                                {...register("facebookUrl")}
                            />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="" className="font-bold">
                                Instagram
                            </label>
                            <input
                                className="px-3 py-2 focus:ring-1 focus:ring-inputfocus focus:outline-none rounded-sm border-gray-600 border"
                                type="text"
                                placeholder="Introduce un enlace"
                                defaultValue={companyDetails?.instagramUrl}
                                {...register("instagramUrl")}
                            />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="" className="font-bold">
                                Whatsapp
                            </label>
                            <input
                                className="px-3 py-2 focus:ring-1 focus:ring-inputfocus focus:outline-none rounded-sm border-gray-600 border"
                                type="text"
                                placeholder="Introduce un enlace"
                                defaultValue={companyDetails?.whatsappUrl}
                                {...register("whatsappUrl")}
                            />
                        </div>

                        <div className="flex flex-col justify-center items-center gap-3 col-span-3">
                            <p className="font-bold">Imagen de Inicio</p>
                            <span>
                                *Para mejor experiencia de usuario, es
                                recomendable subir una{" "}
                                <a
                                    href="https://massageworldcup.com/wp-content/uploads/Landscape.png"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="underline text-blue-600"
                                >
                                    imagen tomada horizontalmente.
                                </a>
                            </span>
                            <div className="p-4 shadow-sm border-gray-200 border">
                                <img
                                    src={imagenPreview}
                                    alt="imagen-producto"
                                    className="rounded-sm aspect-video max-w-3xl"
                                />
                            </div>
                            <label
                                htmlFor="input-imagen-bienvenida"
                                className="flex flex-col justify-around items-center px-10 py-6 bg-white text-blue rounded-lg shadow-sm tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white"
                            >
                                <svg
                                    className="w-6 h-6"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                </svg>
                                <span className="text-gray-400">
                                    Subir otra imagen
                                </span>

                                <input
                                    onInput={handleImageUpload}
                                    id="input-imagen-bienvenida"
                                    type="file"
                                    className="hidden"
                                    {...register("fileImage")}
                                    accept=".png, .jpeg, .jpg"
                                />
                            </label>
                            {mensajeErrorImagen && (
                                <span className="font-bold text-red-700">
                                    Imagen obligatoria
                                </span>
                            )}
                        </div>
                    </div>
                    <input
                        type="submit"
                        className="hidden"
                        ref={submitBttnRef}
                    />
                    <div className="flex items-center justify-center gap-4 mt-10">
                        <Button
                            onClick={() => navigate("/admin/menu")}
                            icon={<FontAwesomeIcon icon={faArrowLeft} />}
                            text={"Regresar"}
                            className="px-5 py-2 items-center flex gap-2 cursor-pointer border-white border text-white bg-primary rounded-md text-xl"
                        />
                        <Button
                            onClick={handleShowModal}
                            icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                            text={"Guardar Cambios"}
                            className="px-8 py-2 items-center flex gap-2 cursor-pointer border-white border text-white bg-primary rounded-md text-xl"
                        />
                        <Modal
                            isOpen={showModal}
                            className={
                                "absolute bg-white border border-gray-500 rounded-sm p-8  top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2s"
                            }
                            overlayClassName={
                                "fixed top-0 right-0 bottom-0 left-0 w-full h-full bg-white/75"
                            }
                        >
                            <div className="flex flex-col gap-8 items-center">
                                <div className="text-xl font-bold flex items-start gap-2">
                                    <FontAwesomeIcon
                                        icon={faTriangleExclamation}
                                        className="text-red-600 mt-1"
                                    />
                                    <div>
                                        ¿Esta seguro que desea guardar los
                                        cambios?
                                    </div>
                                </div>
                                <div className="flex flex-col items-center justify-end gap-2">
                                    <Button
                                        onClick={() =>
                                            submitBttnRef.current.click()
                                        }
                                        text={"Aceptar"}
                                        className="px-6 py-2 items-center flex gap-2 cursor-pointer border-white border text-white bg-primary rounded-md text-lg"
                                        icon={
                                            <FontAwesomeIcon icon={faCheck} />
                                        }
                                    ></Button>
                                    <Button
                                        text={"Cancelar"}
                                        onClick={handleShowModal}
                                        className="px-6 py-2 items-center flex gap-2 cursor-pointer border-white border text-white bg-primary rounded-md text-lg"
                                        icon={<FontAwesomeIcon icon={faX} />}
                                    ></Button>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </form>
            </div>
        </>
    );
}
