import { useForm } from "react-hook-form";
import { actualizarDetallesCategoriaProductoAdmin } from "../../api/admin";
import { useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FormsContext } from "../../../context/forms";
import { isEmpty } from "../../utils";

export function CategoryDetailsForm({ category, submitBtnRef }) {
    const [imagenPreview, setImagenPreview] = useState(category.imgSource);
    const [imageFile, setImageFile] = useState([]);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleImageUpload,
        accept: {
            "image/*": [".png", ".jpeg", ".jpg"],
        },
    });

    const { register, handleSubmit, watch, formState } = useForm({
        mode: "onBlur",
    });

    const catalogueFile = watch("fileCatalogue");

    const formsContext = useContext(FormsContext);

    useEffect(() => {
        if (!isEmpty(formState.errors)) {
            formsContext.updateForm(category.id, "INVALID");
        } else if (isEmpty(formState.errors)) {
            formsContext.updateForm(category.id, "VALID");
        }
    }, [formState, formsContext, category.id]);

    useEffect(() => {
        if (formsContext.isAlreadyInList(category.id)) {
            return;
        }

        formsContext.addForm(category.id, "VALID");
    }, [formsContext, category.id]);

    async function onSubmit(data) {
        const response = await actualizarDetallesCategoriaProductoAdmin(
            category.id,
            data.description,
            data.fileCatalogue,
            imageFile
        );

        console.log(response);
    }

    function handleImageUpload(acceptedFiles) {
        const reader = new FileReader();

        reader.onloadend = () => {
            setImagenPreview(reader.result);
        };

        reader.readAsDataURL(acceptedFiles[0]);

        setImageFile(acceptedFiles);
    }

    return (
        <>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="text-lg"
                onKeyDown={(e) => {
                    if (e.code === "Enter") {
                        e.preventDefault();
                    }
                }}
            >
                <div className="flex justify-center items-center gap-6">
                    <div className="flex flex-col self-start gap-2">
                        <label
                            htmlFor="input-descripcion-categoria"
                            className="font-bold"
                        >
                            Descripcion
                        </label>
                        <textarea
                            rows={8}
                            cols={50}
                            id="input-descripcion-categoria"
                            className="px-3 py-1 focus:ring-1 focus:ring-inputfocus focus:outline-none rounded-sm border-gray-600 border resize-none"
                            defaultValue={category.description}
                            {...register("description", {
                                required: true,
                            })}
                        ></textarea>
                        {formState.errors?.description?.type === "required" && (
                            <span className="text-red-500">
                                Este campo es obligatorio.
                            </span>
                        )}
                    </div>
                    <div className="flex justify-center items-center gap-6">
                        <div className="flex flex-col items-center justify-center gap-2 font-bold">
                            Imagen de Portada
                            <img
                                className="aspect-square object-fill rounded-lg"
                                src={imagenPreview}
                                alt="imagen-portada"
                                width={250}
                            />
                        </div>

                        <div className="flex flex-col items-center justify-center gap-10">
                            <div
                                {...getRootProps()}
                                className="w-56 flex flex-col items-center px-4 py-3 bg-white text-blue rounded-lg shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white"
                            >
                                <svg
                                    className="w-8 h-8"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                </svg>
                                <input
                                    {...getInputProps()}
                                    id="input-imagen-categoria"
                                    className="hidden"
                                    type="file"
                                    {...register("fileImage")}
                                />
                                {isDragActive ? (
                                    <span className="mt-2 text-base leading-normal  bg-white text-blue">
                                        Suelta el archivo aquí ...
                                    </span>
                                ) : (
                                    <span className="mt-2 text-base leading-normal  bg-white text-blue">
                                        Sube una imagen
                                    </span>
                                )}
                            </div>

                            <label
                                htmlFor="input-catalogo-categoria"
                                className="w-56 flex flex-col items-center px-4 py-3 bg-white text-blue rounded-lg shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white"
                            >
                                <svg
                                    className="w-8 h-8"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                </svg>
                                <span className="mt-2 text-base leading-normal">
                                    {catalogueFile?.length > 0
                                        ? `${catalogueFile[0].name.slice(
                                              0,
                                              10
                                          )}..${catalogueFile[0].name.slice(
                                              catalogueFile[0].name.length - 4,
                                              catalogueFile[0].name.length
                                          )}`
                                        : "Sube un catalogo"}
                                </span>
                                <input
                                    id="input-catalogo-categoria"
                                    type="file"
                                    className="hidden"
                                    {...register("fileCatalogue")}
                                    accept=".doc, .docx, .pdf"
                                />
                            </label>
                        </div>
                    </div>
                </div>

                <input ref={submitBtnRef} type="submit" className="hidden" />
            </form>
        </>
    );
}
