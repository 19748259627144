import { useForm } from "react-hook-form";
import { useContext, useEffect } from "react";
import { isEmpty } from "../../utils";
import { FormsContext } from "../../../context/forms";

export default function CategoryNameForm({
    submitBtnRef,
    actualizarNombre,
    id,
    name,
}) {
    const { register, handleSubmit, formState } = useForm({
        mode: "onBlur",
    });
    const formsContext = useContext(FormsContext);

    useEffect(() => {
        if (!isEmpty(formState.errors)) {
            formsContext.updateForm(name, "INVALID");
        } else if (isEmpty(formState.errors)) {
            formsContext.updateForm(name, "VALID");
        }
    }, [formState, formsContext, name]);

    useEffect(() => {
        if (formsContext.isAlreadyInList(name)) {
            return;
        }

        formsContext.addForm(name, "VALID");
    }, [formsContext, name]);

    async function onSubmit(data) {
        const response = await actualizarNombre(id, data.name);

        console.log(response);
    }

    return (
        <>
            <form
                onSubmit={handleSubmit(onSubmit)}
                onKeyDown={(e) => {
                    if (e.code === "Enter") {
                        e.preventDefault();
                    }
                }}
                className="flex flex-col gap-2 w-full text-lg"
            >
                <label htmlFor="input-nombre-categoria" className="font-bold">
                    Nombre de Categoria
                </label>
                <input
                    className="px-3 py-2 focus:ring-1 focus:ring-inputfocus focus:outline-none rounded-sm border-gray-600 border"
                    id="input-nombre-categoria"
                    defaultValue={name}
                    {...register("name", { required: true, minLength: 2 })}
                    placeholder="Nombre"
                />
                {formState.errors?.name?.type === "required" && (
                    <span className="text-red-500">
                        Este campo es obligatorio.
                    </span>
                )}

                {formState.errors?.name?.type === "minLength" && (
                    <span className="text-red-500">
                        Debe ser mayor de 2 caracteres.
                    </span>
                )}

                <input ref={submitBtnRef} type="submit" className="hidden" />
            </form>
        </>
    );
}
