import { useContext, useEffect, useState } from "react";
import Button from "../utils/Button";
import { shuffleArray } from "../utils";
import { getNuestrosTrabajos } from "../api";
import { Link } from "react-router-dom";
import { CompanyDetailsContext } from "../../context/companyDetails";

export default function Inicio() {
    const [shuffledTrabajos, setShuffledTrabajos] = useState([]);
    const companyDetails = useContext(CompanyDetailsContext);

    useEffect(() => {
        const fetchTrabajosExamples = async () => {
            const ejemploTrabajos = await getNuestrosTrabajos();
            const shuffledTrabajos = ejemploTrabajos.map((trabajo) =>
                shuffleArray(trabajo.examples.slice(0))
            );
            setShuffledTrabajos(shuffledTrabajos);
        };
        fetchTrabajosExamples();
    }, []);

    return (
        <main className="inicio-container">
            <section
                className="bienvenida"
                style={{
                    backgroundImage: `url(${companyDetails?.heroImageUrl})`,
                }}
            >
                <div className="bienvenida-header">
                    <div>
                        <h1>Bienvenidos</h1>
                        <p style={{ fontSize: 22 }}>
                            {companyDetails?.description}
                        </p>
                    </div>
                    <Link to={"/presupuestos"}>
                        <Button text="Solicitar Presupuesto " />
                    </Link>
                </div>
            </section>

            <div className="example-products-header">
                <div>
                    <h1>Nuestros trabajos</h1>
                    <div className="example-products-header-underline"></div>
                </div>
            </div>
            <section className="example-products">
                {shuffledTrabajos.map((trabajo) => (
                    <div
                        className="product-image shadow-md p-4"
                        key={trabajo[0].id}
                    >
                        <img
                            className="rounded-sm
                        "
                            src={trabajo[0].imgSource}
                            alt={trabajo[0].name}
                        ></img>
                    </div>
                ))}
            </section>
        </main>
    );
}
