import { Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Inicio from "./components/Inicio/Inicio";
import Productos from "./components/Productos/Productos";
import NuestrosTrabajos from "./components/NuestrosTrabajos/NuestrosTrabajos";
import Presupuestos from "./components/Presupuestos/Presupuestos";
import ProductCategory from "./components/ProductCategory/ProductCategory";
import Login from "./components/admin/Login/Login";
import Menu from "./components/admin/Menu/Menu";
import ProductosAdmin from "./components/admin/ProductosAdmin/ProductosAdmin";
import NuestrosTrabajosAdmin from "./components/admin/NuestrosTrabajosAdmin/NuestrosTrabajosAdmin";
import DatosDeEmpresaAdmin from "./components/admin/DatosDeEmpresaAdmin/DatosDeEmpresaAdmin";
import ProductosAdminEditar from "./components/admin/ProductosAdminEditar/ProdcutosAdminEditar";
import WithAuth from "./components/admin/hoc/WithAuth";
import { CompanyDetailsContext } from "./context/companyDetails";
import { useEffect, useState } from "react";
import { getCompanyDetails } from "./components/api";
import { FormsContext } from "./context/forms";
import NuestrosTrabajosAdminEditar from "./components/admin/NuestrosTrabajosAdminEditar/NuestrosTrabajosAdminEditar";
import { ToastContainer } from "react-toastify";
import NotFound from "./components/NotFound/NotFound";

function App() {
    const [companyDetails, setCompanyDetails] = useState({});

    useEffect(() => {
        const fetchCompanyDetails = async () => {
            const company = await getCompanyDetails();
            setCompanyDetails({ ...company, setCompanyDetails });
        };
        fetchCompanyDetails();
    }, []);

    return (
        <>
            <CompanyDetailsContext.Provider value={companyDetails}>
                <FormsContext.Provider
                    value={{
                        forms: [],
                        addForm(id, status) {
                            this.forms.push({
                                id: id,
                                status: status,
                            });
                        },
                        removeForm(id) {
                            this.forms = this.forms.filter(
                                (form) => form.id !== id
                            );
                        },
                        updateForm(id, formStatus) {
                            this.forms = this.forms.map((form) => {
                                if (form.id === id) {
                                    return { ...form, status: formStatus };
                                }
                                return form;
                            });
                        },
                        isAlreadyInList(id) {
                            return (
                                this.forms.filter((form) => form.id === id)
                                    .length > 0
                            );
                        },
                        isAnyNotValid() {
                            return (
                                this.forms.filter(
                                    (form) => form.status === "INVALID"
                                ).length > 0
                            );
                        },
                        cleanForms() {
                            this.forms = [];
                        },
                    }}
                >
                    {!window.location.pathname.includes("/admin") && <Header />}
                    <Routes>
                        <Route path="/" element={<Inicio />}></Route>
                        <Route
                            path="/productos"
                            element={<Productos />}
                        ></Route>
                        <Route
                            path="/productos/:productCategory"
                            element={<ProductCategory />}
                        ></Route>
                        <Route
                            path="/nuestrostrabajos"
                            element={<NuestrosTrabajos />}
                        ></Route>
                        <Route
                            path="/presupuestos"
                            element={<Presupuestos />}
                        ></Route>
                        <Route path="/admin" element={<Login />}></Route>
                        <Route
                            path="/admin/menu"
                            element={<WithAuth Component={Menu} />}
                        ></Route>
                        <Route
                            path="/admin/productos"
                            element={<WithAuth Component={ProductosAdmin} />}
                        ></Route>
                        <Route
                            path="/admin/productos/editar/:productId"
                            element={
                                <WithAuth Component={ProductosAdminEditar} />
                            }
                        ></Route>
                        <Route
                            path="/admin/nuestrostrabajos"
                            element={
                                <WithAuth Component={NuestrosTrabajosAdmin} />
                            }
                        ></Route>
                        <Route
                            path="/admin/nuestrostrabajos/editar/:productId"
                            element={
                                <WithAuth
                                    Component={NuestrosTrabajosAdminEditar}
                                />
                            }
                        ></Route>
                        <Route
                            path="/admin/datosdeempresa"
                            element={
                                <WithAuth Component={DatosDeEmpresaAdmin} />
                            }
                        ></Route>
                        <Route path="*" element={<NotFound />}></Route>
                    </Routes>
                    {!window.location.pathname.includes("/admin") && <Footer />}
                </FormsContext.Provider>
            </CompanyDetailsContext.Provider>
            <ToastContainer />
        </>
    );
}

export default App;
