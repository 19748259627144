import axios from "axios";
import { SERVER_URL } from ".";
import { cookies } from "../constants";

const jwtToken = () => {
    return cookies.get("JWT_ACCESS_COOKIE_NAME");
};

const authConfiguration = (contentType = "application/json") => {
    return {
        headers: {
            Authorization: `Bearer ${jwtToken()}`,
            "Content-Type": contentType,
        },
    };
};

export async function validateSession(navigate) {
    try {
        if (!Boolean(jwtToken())) {
            return false;
        }

        const res = await axios.get(
            `${SERVER_URL}/api/v1/validate`,
            authConfiguration()
        );

        if (Boolean(res.data.msg)) {
            return false;
        }

        return res.data.isAuthenticated;
    } catch (error) {
        cookies.remove("JWT_ACCESS_COOKIE_NAME");
        return false;
    }
}

export async function iniciarSesionAdmin(data) {
    try {
        const response = await axios.post(`${SERVER_URL}/api/v1/login`, data);
        return response?.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getCategoriasProductosAdmin() {
    try {
        const response = await axios.get(
            `${SERVER_URL}/api/v1/products/category`,
            authConfiguration()
        );

        const { items } = response?.data;

        return items;
    } catch (error) {
        console.log(error);

        return null;
    }
}

export async function getCategoriasNuestrosTrabajosAdmin() {
    try {
        const response = await axios.get(
            `${SERVER_URL}/api/v1/work/category`,
            authConfiguration()
        );

        const { items } = response?.data;

        return items;
    } catch (error) {
        console.log(error);

        return null;
    }
}

export async function crearNuevaCategoriaProductoAdmin(name) {
    try {
        const nuevaCategoria = { name };

        const response = await axios.post(
            `${SERVER_URL}/api/v1/products/category`,
            nuevaCategoria,
            authConfiguration()
        );

        const categoria = response?.data;

        return categoria;
    } catch (error) {
        console.log(error);

        return null;
    }
}

export async function crearNuevaCategoriaTrabajosAdmin(name) {
    try {
        const nuevaCategoria = { name };

        const response = await axios.post(
            `${SERVER_URL}/api/v1/work/category`,
            nuevaCategoria,
            authConfiguration()
        );

        const categoria = response?.data;

        return categoria;
    } catch (error) {
        console.log(error);

        return null;
    }
}

export async function eliminarCategoriaProductoAdmin(id) {
    try {
        const response = await axios.delete(
            `${SERVER_URL}/api/v1/products/category/${id}`,
            authConfiguration()
        );

        console.log(response?.data.message);

        return true;
    } catch (error) {
        console.log(error);

        return false;
    }
}

export async function eliminarCategoriaTrabajosAdmin(id) {
    try {
        const response = await axios.delete(
            `${SERVER_URL}/api/v1/work/category/${id}`,
            authConfiguration()
        );

        console.log(response?.data.message);

        return true;
    } catch (error) {
        console.log(error);

        return false;
    }
}

export async function obtenerCategoriaProductoAdmin(id) {
    try {
        const response = await axios.get(
            `${SERVER_URL}/api/v1/products/category/${id}`,
            authConfiguration()
        );

        const categoria = response?.data;

        return categoria;
    } catch (error) {
        console.log(error);

        return null;
    }
}

export async function obtenerCategoriaTrabajosAdmin(id) {
    try {
        const response = await axios.get(
            `${SERVER_URL}/api/v1/work/category/${id}`,
            authConfiguration()
        );

        const categoria = response?.data;

        return categoria;
    } catch (error) {
        console.log(error);

        return null;
    }
}

export async function actualizarNombreCategoriaProductoAdmin(
    categoryId,
    newName
) {
    try {
        const response = await axios.patch(
            `${SERVER_URL}/api/v1/products/category/${categoryId}`,
            { name: newName },
            authConfiguration()
        );

        const categoria = response?.data;

        return categoria.message;
    } catch (error) {
        console.log(error);

        return null;
    }
}

export async function actualizarNombreCategoriaTrabajosAdmin(
    categoryId,
    newName
) {
    try {
        const response = await axios.patch(
            `${SERVER_URL}/api/v1/work/category/${categoryId}`,
            { name: newName },
            authConfiguration()
        );

        const categoria = response?.data;

        return categoria.message;
    } catch (error) {
        console.log(error);

        return null;
    }
}

export async function actualizarDetallesCategoriaProductoAdmin(
    categoryId,
    newDescription,
    newCataloge,
    newImage
) {
    try {
        const data = new FormData();
        data.append("description", newDescription);

        if (newCataloge.length !== 0) {
            data.append("fileCatalogue", newCataloge[0]);
        }

        if (newImage.length !== 0) {
            data.append("fileImage", newImage[0]);
        }

        const response = await axios.patch(
            `${SERVER_URL}/api/v1/products/category/${categoryId}/details`,
            data,
            authConfiguration("multipart/form-data")
        );

        const categoria = response?.data;

        return categoria.message;
    } catch (error) {
        console.log(error);

        return null;
    }
}

export async function obtenerListaProductosPorCategoriaAdmin(categoryId) {
    try {
        const response = await axios.get(
            `${SERVER_URL}/api/v1/products/category/${categoryId}/examples`,
            authConfiguration()
        );

        const examples = response?.data;

        return examples.items;
    } catch (error) {
        console.log(error);

        return null;
    }
}

export async function obtenerListaTrabajosPorCategoriaAdmin(categoryId) {
    try {
        const response = await axios.get(
            `${SERVER_URL}/api/v1/work/category/${categoryId}/examples`,
            authConfiguration()
        );

        const examples = response?.data;

        return examples.items;
    } catch (error) {
        console.log(error);

        return null;
    }
}

export async function crearEjemploCategoriaProductoAdmin(
    categoryId,
    newName,
    newImage,
    newDescription = ""
) {
    try {
        const data = new FormData();
        data.append("description", newDescription);
        data.append("name", newName);
        data.append("fileImage", newImage[0]);

        const response = await axios.post(
            `${SERVER_URL}/api/v1/products/category/${categoryId}/examples`,
            data,
            authConfiguration("multipart/form-data")
        );

        const nuevoEjemplo = response?.data;

        return nuevoEjemplo;
    } catch (error) {
        console.log(error);

        return null;
    }
}

export async function crearEjemploCategoriaTrabajosAdmin(
    categoryId,
    newName,
    newImage,
    newDescription = ""
) {
    try {
        const data = new FormData();
        data.append("description", newDescription);
        data.append("name", newName);
        data.append("fileImage", newImage[0]);

        const response = await axios.post(
            `${SERVER_URL}/api/v1/work/category/${categoryId}/examples`,
            data,
            authConfiguration("multipart/form-data")
        );

        const nuevoEjemplo = response?.data;

        return nuevoEjemplo;
    } catch (error) {
        console.log(error);

        return null;
    }
}

export async function eliminarEjemploCategoriaProductoAdmin(
    categoryId,
    ejemploId
) {
    try {
        const response = await axios.delete(
            `${SERVER_URL}/api/v1/products/category/${categoryId}/examples/${ejemploId}`,
            authConfiguration()
        );

        const mensaje = response?.data;

        return mensaje;
    } catch (error) {
        console.log(error);

        return null;
    }
}

export async function eliminarEjemploCategoriaTrabajosAdmin(
    categoryId,
    ejemploId
) {
    try {
        const response = await axios.delete(
            `${SERVER_URL}/api/v1/work/category/${categoryId}/examples/${ejemploId}`,
            authConfiguration()
        );

        const mensaje = response?.data;

        return mensaje;
    } catch (error) {
        console.log(error);

        return null;
    }
}

export async function actualizarEjemploCategoriaProductoAdmin(
    categoryId,
    ejemploId,
    newName,
    newDescription,
    newImage
) {
    try {
        const data = new FormData();
        data.append("description", newDescription);
        data.append("name", newName);

        if (newImage.length !== 0) {
            data.append("fileImage", newImage[0]);
        }

        const response = await axios.patch(
            `${SERVER_URL}/api/v1/products/category/${categoryId}/examples/${ejemploId}`,
            data,
            authConfiguration("multipart/form-data")
        );

        const nuevoEjemplo = response?.data;

        return nuevoEjemplo;
    } catch (error) {
        console.log(error);

        return null;
    }
}

export async function actualizarEjemploCategoriaTrabajosAdmin(
    categoryId,
    ejemploId,
    newName,
    newDescription,
    newImage
) {
    try {
        const data = new FormData();
        data.append("description", newDescription);
        data.append("name", newName);

        if (newImage.length !== 0) {
            data.append("fileImage", newImage[0]);
        }

        const response = await axios.patch(
            `${SERVER_URL}/api/v1/work/category/${categoryId}/examples/${ejemploId}`,
            data,
            authConfiguration("multipart/form-data")
        );

        const nuevoEjemplo = response?.data;

        return nuevoEjemplo;
    } catch (error) {
        console.log(error);

        return null;
    }
}

export async function obtenerDatosDeEmpresa() {
    try {
        const response = await axios.get(
            `${SERVER_URL}/api/v1/company/details`,
            authConfiguration("multipart/form-data")
        );

        const datosDeEmpresa = response?.data;

        return datosDeEmpresa;
    } catch (error) {
        console.log(error);

        return null;
    }
}

export async function actualizarDatosDeEmpresa(payload) {
    try {
        const data = new FormData();
        data.append("address", payload.address);

        if (payload.fileCatalogue.length > 0) {
            data.append("fileCatalogue", payload.fileCatalogue[0]);
        }

        if (payload.fileImage.length > 0) {
            data.append("fileImage", payload.fileImage[0]);
        }

        data.append("description", payload.description);
        data.append("email", payload.email);
        data.append("phone", payload.phone);

        if (payload.facebookUrl !== "") {
            data.append("facebookUrl", payload.facebookUrl);
        }

        if (payload.instagram !== "") {
            data.append("instagramUrl", payload.instagramUrl);
        }

        if (payload.whatsappUrl !== "") {
            data.append("whatsappUrl", payload.whatsappUrl);
        }

        const response = await axios.post(
            `${SERVER_URL}/api/v1/company/details`,
            data,
            authConfiguration("multipart/form-data")
        );

        const datosDeEmpresa = response?.data;

        return datosDeEmpresa;
    } catch (error) {
        console.log(error);

        return null;
    }
}
