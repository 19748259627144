const QA_HOSTNAMES = ["localhost:3000", "127.0.0.1:3000", "192.168.100.159"];

const QA_SERVER_URL = "http://192.168.100.159/";
const PROD_SERVER_URL = "https://expansionalumet.com/";

export const calculateServerUrlByHostname = () => {
    const currentHostname = window.location.host;

    if (QA_HOSTNAMES.includes(currentHostname)) {
        return QA_SERVER_URL;
    }

    return PROD_SERVER_URL;
};
