import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AdminNav from "../../utils/AdminNav";
import {
    crearNuevaCategoriaTrabajosAdmin,
    eliminarCategoriaTrabajosAdmin,
    getCategoriasNuestrosTrabajosAdmin,
    obtenerCategoriaTrabajosAdmin,
} from "../../api/admin";
import Button from "../../utils/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faPencil,
    faPlus,
    faTrash,
    faTriangleExclamation,
    faX,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../utils/Spinner";
import Modal from "react-modal";
import { FormsContext } from "../../../context/forms";
import { toast } from "react-toastify";

export default function NuestrosTrabajosAdmin() {
    const [trabajosCategorias, setTrabajosCategorias] = useState([]);
    const [isLoadingCategorias, setIsLoadingCategorias] = useState(true);
    const [showNuevaCategoria, setShowNuevaCategoria] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({});

    const formsContext = useContext(FormsContext);

    const navigate = useNavigate();
    const { state } = useLocation();

    const notifyError = () =>
        toast.error("No se admite un nombre vacio.", {
            position: "top-center",
        });

    useEffect(() => {
        const getProductos = async () => {
            const res = await getCategoriasNuestrosTrabajosAdmin();

            setTrabajosCategorias(res);
            setIsLoadingCategorias(false);
        };

        if (state?.updateSuccessful) {
            toast.success(state.message, {
                position: "top-center",
            });
        }
        formsContext.cleanForms();
        getProductos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleNuevaCategoria() {
        setShowNuevaCategoria(!showNuevaCategoria);
    }

    function handleInput(e) {
        setInputValue(e.target.value);
    }

    async function handleCrearNuevaCategoria() {
        if (inputValue.trim() === "") {
            notifyError();
            return null;
        }

        const response = await crearNuevaCategoriaTrabajosAdmin(inputValue);

        navigate(`/admin/nuestrostrabajos/editar/${response.id}`, {
            state: response,
        });
    }

    async function handleEditarCategoria(id) {
        const response = await obtenerCategoriaTrabajosAdmin(id);

        navigate(`/admin/nuestrostrabajos/editar/${response.id}`, {
            state: response,
        });
    }

    async function handleEliminarProductoCategoria(id) {
        const response = await eliminarCategoriaTrabajosAdmin(id);

        if (response) {
            const res = await getCategoriasNuestrosTrabajosAdmin();

            setTrabajosCategorias(res);
            toast.success("Categoria eliminada correctamente.", {
                position: "top-center",
            });
        } else {
            toast.error(
                "Ha ocurrido un error, vuelve a intentarlo mas tarde.",
                {
                    position: "top-center",
                }
            );
        }

        setModalIsOpen(false);
    }

    function handleShowModal(id, nombreCategoria) {
        setSelectedCategory({ id: id, name: nombreCategoria });
        setModalIsOpen(!modalIsOpen);
    }

    return (
        <>
            <AdminNav navigate={navigate} />
            <div className="flex flex-col items-center mt-4 ">
                <div className="flex flex-col gap-8  bg-gray-50 shadow-md p-8">
                    <div className="flex flex-col gap-2 justify-center items-center">
                        <div className="flex justify-between w-full">
                            <h1 className="self-start px-8 text-gray-600">
                                Nuestros Trabajos
                            </h1>
                            <div className="pr-8">
                                {!showNuevaCategoria && (
                                    <Button
                                        icon={<FontAwesomeIcon icon={faPlus} />}
                                        onClick={handleNuevaCategoria}
                                        text={"Nueva Categoria"}
                                        className="flex items-center gap-2 px-4 py-2 cursor-pointer border-white border text-white bg-primary rounded text-xl"
                                    ></Button>
                                )}
                            </div>
                        </div>
                        {showNuevaCategoria && (
                            <div className="flex flex-col justify-center items-center gap-4 bg-gray-200 p-8">
                                <label
                                    htmlFor="nueva-categoria"
                                    className="text-xl"
                                >
                                    Introduce el nombre de la nueva categoria:
                                </label>
                                <input
                                    type="text"
                                    id="nueva-categoria"
                                    placeholder="Ingresa nombre de la categoría..."
                                    className="w-full px-2 py-1 focus:ring-1 focus:ring-inputfocus focus:outline-none rounded-sm border-gray-400 border"
                                    value={inputValue}
                                    onChange={handleInput}
                                />
                                <Button
                                    onClick={handleCrearNuevaCategoria}
                                    text={"Crear y Editar"}
                                    icon={<FontAwesomeIcon icon={faPlus} />}
                                    className="flex items-center gap-2 px-4 py-2 cursor-pointer border-white border text-white bg-primary rounded text-xl"
                                />
                                <Button
                                    onClick={handleNuevaCategoria}
                                    text={"Cerrar"}
                                    icon={<FontAwesomeIcon icon={faXmark} />}
                                    className="flex items-center gap-2 px-4 py-2 cursor-pointer border-white border text-white bg-primary rounded text-xl"
                                ></Button>
                            </div>
                        )}
                    </div>

                    {isLoadingCategorias && <Spinner text="Cargando..." />}

                    {!showNuevaCategoria && !isLoadingCategorias && (
                        <div className="flex flex-col ">
                            <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
                                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="overflow-hidden">
                                        <table className="min-w-full">
                                            <thead className="bg-gray-200 border-b">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="text-xl font-bold text-gray-900 px-6 py-4 text-left"
                                                    >
                                                        Categor&iacute;a
                                                    </th>

                                                    <th
                                                        scope="col"
                                                        className="text-xl font-bold text-gray-900 px-6 py-4 text-left"
                                                    >
                                                        Opciones
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {trabajosCategorias.map(
                                                    (trabajo) => (
                                                        <tr
                                                            className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                                            key={[trabajo.id]}
                                                        >
                                                            <td className="px-6 pr-32 py-4 whitespace-nowrap text-xl font-medium text-gray-900">
                                                                {
                                                                    trabajo.category
                                                                }
                                                            </td>

                                                            <td className="text-xl text-gray-900 font-light px-6 py-4 whitespace-nowrap flex gap-2">
                                                                <Button
                                                                    onClick={() =>
                                                                        handleEditarCategoria(
                                                                            trabajo.id
                                                                        )
                                                                    }
                                                                    text={
                                                                        "Editar"
                                                                    }
                                                                    icon={
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faPencil
                                                                            }
                                                                        />
                                                                    }
                                                                    className="flex items-center gap-4 px-4 py-2 cursor-pointer border-white border text-white bg-primary rounded text-xl"
                                                                ></Button>
                                                                <Button
                                                                    onClick={() => {
                                                                        handleShowModal(
                                                                            trabajo.id,
                                                                            trabajo.category
                                                                        );
                                                                    }}
                                                                    text={
                                                                        "Eliminar"
                                                                    }
                                                                    icon={
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faTrash
                                                                            }
                                                                        />
                                                                    }
                                                                    className="px-4 py-2 gap-4 flex items-center cursor-pointer border-white border text-white bg-primary rounded text-xl"
                                                                ></Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                        <Modal
                                            isOpen={modalIsOpen}
                                            className={
                                                "absolute bg-white border border-gray-500 rounded-sm p-8  top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2s"
                                            }
                                            overlayClassName={
                                                "fixed top-0 right-0 bottom-0 left-0 w-full h-full bg-white/75"
                                            }
                                        >
                                            <div className="flex flex-col gap-8 items-center">
                                                <p className="text-3xl">
                                                    Eliminar Categoria:{" "}
                                                    <span className="font-bold text-black">
                                                        {selectedCategory.name}
                                                    </span>
                                                </p>
                                                <div className="text-xl font-bold flex items-start gap-2">
                                                    <FontAwesomeIcon
                                                        icon={
                                                            faTriangleExclamation
                                                        }
                                                        className="text-red-600 mt-1"
                                                    />
                                                    <div>
                                                        Este cambio ser&aacute;{" "}
                                                        <span className="text-red-600">
                                                            irreversible
                                                        </span>
                                                        , esta seguro que desea
                                                        eliminarla de manera
                                                        permanentemente?
                                                    </div>
                                                </div>
                                                <div className="flex flex-col items-center justify-end gap-2">
                                                    <Button
                                                        text={"Aceptar"}
                                                        onClick={() =>
                                                            handleEliminarProductoCategoria(
                                                                selectedCategory.id
                                                            )
                                                        }
                                                        className="px-6 py-2 items-center flex gap-2 cursor-pointer border-white border text-white bg-primary rounded-md text-lg"
                                                        icon={
                                                            <FontAwesomeIcon
                                                                icon={faCheck}
                                                            />
                                                        }
                                                    ></Button>
                                                    <Button
                                                        text={"Cancelar"}
                                                        onClick={
                                                            handleShowModal
                                                        }
                                                        className="px-6 py-2 items-center flex gap-2 cursor-pointer border-white border text-white bg-primary rounded-md text-lg"
                                                        icon={
                                                            <FontAwesomeIcon
                                                                icon={faX}
                                                            />
                                                        }
                                                    ></Button>
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
