const Button = ({
    text,
    onClick,
    className = "cta-default-btn",
    type = "button",
    icon = null,
}) => {
    return (
        <button className={className} onClick={onClick} type={type}>
            {icon} {text}
        </button>
    );
};

export default Button;
