import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "./Button";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import logo_expansion_alumet from "../../Assets/Images/logo_expansion_alumet.png";

export default function AdminNav({ navigate }) {
    return (
        <>
            <div className="w-full flex border-b-2 border-logo text-gray-800 justify-between items-center py-4 px-8">
                <div className="flex items-center gap-4">
                    <img
                        src={logo_expansion_alumet}
                        alt="Logo Expansion alumet"
                        width={180}
                    />
                    <h1 className="text-center">Panel de Administracion</h1>
                </div>
                <Button
                    onClick={() => navigate("/admin/menu")}
                    text={"Menu"}
                    className={
                        "px-8 py-2 flex items-center gap-2 cursor-pointer border-white border text-white bg-primary rounded text-lg"
                    }
                    icon={<FontAwesomeIcon icon={faBars} />}
                ></Button>
            </div>
        </>
    );
}
