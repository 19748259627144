import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import Button from "../utils/Button";
import { CompanyDetailsContext } from "../../context/companyDetails";
import { enviarCorreoPresupuesto } from "../api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Presupuestos() {
    const companyDetails = useContext(CompanyDetailsContext);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    async function onSubmit(data) {
        const response = await enviarCorreoPresupuesto(data);
        if (response.includes("Invalid")) {
            notifyError();
        }

        notifySuccess();
        reset();
    }

    const notifySuccess = () =>
        toast.success("Mensaje enviado con exito!", {
            position: "top-center",
        });

    const notifyError = () =>
        toast.success("Ha ocurrido un error, vuelve a intentarlo mas tarde.", {
            position: "top-center",
        });

    return (
        <div className="presupuesto-container">
            <h1>Solicitud de Presupuesto</h1>
            <hr></hr>
            <p>
                Puede solicitar un presupuesto enviando un mail al correo{" "}
                {companyDetails?.email} o llenando el siguiente formulario para
                ponernos en contacto contigo lo antes posible.
            </p>
            <form onSubmit={handleSubmit(onSubmit)} className="form-container">
                <label>
                    <input
                        name="name"
                        type="text"
                        placeholder="Nombre"
                        {...register("name", { required: true, minLength: 2 })}
                    ></input>
                    {errors?.name?.type === "required" && (
                        <span className="text-red-500">
                            Este campo es obligatorio.
                        </span>
                    )}

                    {errors?.name?.type === "minLength" && (
                        <span className="text-red-500">
                            Debe ser mayor de 2 caracteres.
                        </span>
                    )}
                </label>
                <label>
                    <input
                        name="address"
                        type="text"
                        placeholder="Direcci&oacute;n"
                        {...register("address", {
                            required: true,
                            minLength: 2,
                        })}
                    ></input>
                    {errors?.address?.type === "required" && (
                        <span className="text-red-500">
                            Este campo es obligatorio.
                        </span>
                    )}

                    {errors?.address?.type === "minLength" && (
                        <span className="text-red-500">
                            Debe ser mayor de 2 caracteres.
                        </span>
                    )}
                </label>
                <label>
                    <input
                        name="phone"
                        type="number"
                        placeholder="Tel&eacute;fono"
                        {...register("phone", { required: true, minLength: 6 })}
                    ></input>
                    {errors?.phone?.type === "required" && (
                        <span className="text-red-500">
                            Este campo es obligatorio.
                        </span>
                    )}

                    {errors?.phone?.type === "minLength" && (
                        <span className="text-red-500">
                            Debe ser mayor de 6 caracteres.
                        </span>
                    )}
                </label>
                <label>
                    <input
                        name="email"
                        type="email"
                        placeholder="Email"
                        {...register("email", { required: true })}
                    ></input>
                    {errors?.email?.type === "required" && (
                        <span className="text-red-500">
                            Este campo es obligatorio.
                        </span>
                    )}
                </label>
                <label className="comentarios-obs">
                    <textarea
                        name="details"
                        placeholder="Comentarios y Observaciones..."
                        {...register("details", {
                            required: true,
                        })}
                    ></textarea>
                    {errors?.details?.type === "required" && (
                        <span className="text-red-500">
                            Este campo es obligatorio.
                        </span>
                    )}
                </label>
                <label className=" hidden">
                    <input
                        name="consent"
                        type="checkbox"
                        {...register("consent")}
                        defaultChecked={true}
                    ></input>
                    <span>
                        He leido y acepto la clausula de consentimiento para
                        tratamiento de datos personales
                    </span>
                </label>
                {errors?.consent?.type === "required" && (
                    <span className="text-red-500 self-start -mt-6">
                        Debe aceptar la clausula de tratamiento de datos.
                    </span>
                )}
                <Button type="submit" text="Enviar"></Button>

                <ToastContainer />
            </form>
        </div>
    );
}
