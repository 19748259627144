import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import getProducts from "../api";
import Button from "../utils/Button";
import { CompanyDetailsContext } from "../../context/companyDetails";

export default function Productos() {
    const [productos, setProductos] = useState([]);

    const companyDetails = useContext(CompanyDetailsContext);

    useEffect(() => {
        const fetchProducts = async () => {
            const productos = await getProducts();
            setProductos(productos);
        };
        fetchProducts();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    const fixedDescriptionDisplay = (description = "", limit = 160) => {
        if (description.length <= limit) {
            return description;
        }

        return `${description.slice(0, limit)}...`;
    };

    return (
        <div className="productos-container">
            <h1>Productos</h1>
            <hr></hr>
            <br></br>
            <ul className="product-categories">
                {productos.map((producto) => (
                    <li className="product-category-item" key={producto.id}>
                        <div className="product-category-item-header">
                            <img
                                src={producto.imgSource}
                                alt={producto.category}
                            ></img>
                            <h3>{producto.category}</h3>
                        </div>
                        <div className="product-category-item-divider"></div>
                        <div className="product-category-item-body">
                            <p>
                                {fixedDescriptionDisplay(producto.description)}
                            </p>
                            <Link
                                to={`/productos/${producto.category}`}
                                state={{ producto }}
                            >
                                <Button text="Ver Detalles" />
                            </Link>
                        </div>
                    </li>
                ))}
            </ul>
            <a
                href={companyDetails?.catalogueUrl}
                target="_blank"
                rel="noreferrer"
                className="cta-default-btn hover:text-white"
            >
                Descargar Catalogo
            </a>
        </div>
    );
}
