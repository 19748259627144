import { useContext, useEffect, useState } from "react";
import Button from "../../utils/Button";
import CategoryExampleForm from "./CategoryExampleForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FormsContext } from "../../../context/forms";

export default function CategoryExamplesForm({
    category,
    categoryId,
    ejemplosDeProductos,
    crearEjemplo,
    actualizarEjemplo,
    eliminarEjemplo,
    submitRefList,
    setSubmitRefList,
}) {
    const [nuevosEjemplos, setNuevosEjemplos] = useState(ejemplosDeProductos);
    const formsContext = useContext(FormsContext);

    useEffect(() => {
        const nuevosEjemplosConFlags = ejemplosDeProductos.map((ejemplo) => ({
            ...ejemplo,
            toBeDeleted: false,
            isNew: false,
            toBeUpdated: false,
        }));
        setNuevosEjemplos(nuevosEjemplosConFlags);
    }, [ejemplosDeProductos]);

    function handleAñadirEjemplo() {
        setNuevosEjemplos([
            ...nuevosEjemplos,
            {
                id: Math.floor(Math.random() * (1000 - 500 + 1) + 500) * -1,
                isNew: true,
                toBeDeleted: false,
                toBeCreated: true,
                imgSource: "",
                product: {
                    id: category.id,
                },
            },
        ]);
    }

    function cleanEjemploProducto(id) {
        const ejemplosAConservar = nuevosEjemplos.filter(
            (ejemplo) => ejemplo.id !== id
        );
        setNuevosEjemplos(ejemplosAConservar);
    }

    function handleProductosAEliminar(id) {
        const ejemplo = nuevosEjemplos.find((ejemplo) => ejemplo.id === id);

        if (ejemplo.isNew) {
            const ejemplosAConservar = nuevosEjemplos.filter(
                (ejemplo) => ejemplo.id !== id
            );
            const submitRefsAConservar = submitRefList.filter(
                (input) => input.id !== id
            );
            setSubmitRefList(submitRefsAConservar);
            setNuevosEjemplos(ejemplosAConservar);
            formsContext.removeForm(id);
            return;
        }

        const nuevosEjemplosConFlagChanged = nuevosEjemplos.map((ejemplo) => {
            if (ejemplo.id === id) {
                return {
                    ...ejemplo,
                    toBeDeleted: !ejemplo.toBeDeleted,
                };
            }
            return ejemplo;
        });

        setNuevosEjemplos(nuevosEjemplosConFlagChanged);
    }

    function transitionProductAsNotNew(id) {
        const nuevosEjemplosChanged = nuevosEjemplos.map((ejemplo) => {
            if (ejemplo.id === id) {
                return {
                    ...ejemplo,
                    isNew: false,
                };
            }
            return ejemplo;
        });
        setNuevosEjemplos(nuevosEjemplosChanged);
    }

    function transitionProductToBeUpdated(id) {
        const nuevosEjemplosChanged = nuevosEjemplos.map((ejemplo) => {
            if (ejemplo.id === id) {
                return {
                    ...ejemplo,
                    toBeUpdated: true,
                };
            }
            return ejemplo;
        });
        setNuevosEjemplos(nuevosEjemplosChanged);
    }

    return (
        <div className="flex flex-col justify-center items-center gap-6 w-full text-lg">
            <p className="text-center text-2xl">
                {window.location.pathname.includes("/productos")
                    ? "Productos"
                    : "Trabajos"}{" "}
                de Categoria
            </p>
            <div className="flex items-center gap-10 flex-wrap">
                {nuevosEjemplos.map((producto) => (
                    <CategoryExampleForm
                        key={producto.id}
                        ejemploProducto={producto}
                        categoryId={categoryId}
                        handleProductosAEliminar={handleProductosAEliminar}
                        transitionProductAsNotNew={transitionProductAsNotNew}
                        transitionProductToBeUpdated={
                            transitionProductToBeUpdated
                        }
                        cleanEjemploProducto={cleanEjemploProducto}
                        crearEjemplo={crearEjemplo}
                        actualizarEjemplo={actualizarEjemplo}
                        eliminarEjemplo={eliminarEjemplo}
                        setSubmitRefList={setSubmitRefList}
                        submitRefList={submitRefList}
                    />
                ))}
                <Button
                    text={"Nuevo"}
                    icon={<FontAwesomeIcon icon={faPlus} />}
                    onClick={handleAñadirEjemplo}
                    className="px-6 py-2 items-center flex gap-2 cursor-pointer border-white border text-white bg-primary rounded-md text-lg"
                />
            </div>
        </div>
    );
}
