import { Link, useLocation } from "react-router-dom";
import Button from "../utils/Button";
import { useEffect, useState } from "react";
import getProducts from "../api";

export default function ProductCategory() {
    const { state } = useLocation();
    const productCategory = state.producto;
    const [productos, setProductos] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            const productos = await getProducts();
            setProductos(productos);
        };

        fetchProducts();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <div className="productos-categoria">
            <ul className="products-nav" style={{ paddingTop: "1rem" }}>
                {productos.map((producto) => (
                    <Link
                        to={`/productos/${producto.category}`}
                        state={{ producto }}
                    >
                        <li
                            className={
                                producto.category === productCategory.category
                                    ? "active"
                                    : "in-active"
                            }
                            key={producto.id}
                        >
                            {producto.category}
                        </li>
                    </Link>
                ))}
            </ul>
            <div className="productos-categoria-title">
                <h1>{productCategory.category}</h1>
                <hr></hr>
            </div>

            <p className="productos-categoria-description">
                {state.producto.description}
            </p>
            <ul className="producto-por-categoria">
                {productCategory.products.map((producto) => (
                    <li
                        className="producto-por-categoria-item shadow-md p-4"
                        key={producto.id}
                    >
                        <img src={producto.imgSource} alt={producto.name}></img>
                        <h3 className="font-bold">{producto.name}</h3>
                        <p>{producto.description}</p>
                    </li>
                ))}
            </ul>
            <div className="producto-categoria-btns">
                <a
                    href={state.producto.catalogueUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="cta-default-btn hover:text-white"
                >
                    Descargar Catalogo
                </a>
                <Link to={"/productos"}>
                    <Button text={"⬅️ Productos"}></Button>
                </Link>
            </div>
        </div>
    );
}
