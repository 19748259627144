import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import getProducts from "../api";
import useSticky from "../../hooks/useSticky";
import { CompanyDetailsContext } from "../../context/companyDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebookSquare,
    faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export default function Navbar({ handleNavOpen }) {
    const [isProductOpen, setIsProductOpen] = useState(false);
    const [productos, setProductos] = useState([]);
    const { sticky, stickyRef } = useSticky();

    const companyDetails = useContext(CompanyDetailsContext);

    useEffect(() => {
        const fetchProducts = async () => {
            const productos = await getProducts();
            setProductos(productos);
        };

        fetchProducts();
    }, []);

    function hanldeIsProductOpen(e) {
        e.stopPropagation();
        setIsProductOpen(!isProductOpen);
    }

    return (
        <>
            <nav className="nav-container">
                <ul className="nav-options">
                    <li onClick={handleNavOpen}>
                        <Link to={"/"}>Inicio</Link>
                    </li>
                    <li className="nav-productos" onClick={handleNavOpen}>
                        <Link to={"/productos"}>Productos</Link>
                        <span onClick={hanldeIsProductOpen}>
                            {isProductOpen ? "-" : "+"}
                        </span>
                        {isProductOpen && (
                            <ul className="product-clasification">
                                {productos.map((producto) => (
                                    <li key={producto.id}>
                                        <Link
                                            to={`/productos/${producto.category}`}
                                            state={{
                                                producto,
                                            }}
                                        >
                                            {producto.category}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                    <li onClick={handleNavOpen}>
                        <Link to={"/nuestrostrabajos"}>Nuestros Trabajos</Link>
                    </li>
                    <li onClick={handleNavOpen}>
                        <Link to={"/presupuestos"}>Presupuestos</Link>
                    </li>
                    <li className="socials">
                        <a href={companyDetails?.facebokUrl || "#"}>
                            <FontAwesomeIcon
                                icon={faFacebookSquare}
                                className="text-2xl"
                            />
                        </a>
                        <a href={`mailto:${companyDetails?.email}`}>
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                className="text-2xl"
                            />
                        </a>
                        <a
                            href={companyDetails?.whatsappUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon
                                icon={faWhatsapp}
                                className="text-2xl"
                            />
                        </a>
                    </li>
                </ul>
            </nav>

            {/*Dektop Version*/}
            <nav
                ref={stickyRef}
                className={
                    sticky
                        ? "nav-container-desktop sticky"
                        : "nav-container-desktop"
                }
            >
                <ul className="nav-links-desktop">
                    <li>
                        <Link to={"/"}>Inicio</Link>
                    </li>
                    <li className="productos-nav-element-desktop">
                        <Link to={"/productos"}>Productos</Link>
                        <ul className="product-clasification-desktop">
                            {productos.map((producto) => (
                                <li key={producto.id}>
                                    <Link
                                        to={`/productos/${producto.category}`}
                                        state={{
                                            producto,
                                        }}
                                    >
                                        {producto.category}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </li>
                    <li>
                        <Link to={"/nuestrostrabajos"}>Nuestros Trabajos</Link>
                    </li>
                    <li>
                        <Link to={"/presupuestos"}>Presupuestos</Link>
                    </li>
                </ul>
                <ul className="socials-links-desktop-container">
                    <li className="socials-links-desktop">
                        <a href={companyDetails?.facebokUrl || "#"}>
                            <FontAwesomeIcon
                                icon={faFacebookSquare}
                                className="text-3xl"
                            />
                        </a>
                        <a href={`mailto:${companyDetails?.email}`}>
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                className="text-3xl"
                            />
                        </a>
                        <a
                            href={companyDetails?.whatsappUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon
                                icon={faWhatsapp}
                                className="text-3xl"
                            />
                        </a>
                    </li>
                </ul>
            </nav>
            <div
                style={{
                    height: sticky
                        ? `${stickyRef.current?.clientHeight}px`
                        : "0px",
                }}
            />
        </>
    );
}
