import { useLocation, useNavigate } from "react-router-dom";
import AdminNav from "../../utils/AdminNav";
import CategoryNameForm from "../forms/CategoryNameForm";
import { useContext, useEffect, useRef, useState } from "react";
import Button from "../../utils/Button";
import { CategoryDetailsForm } from "../forms/CategoryDetailsForm";
import CategoryExamplesForm from "../forms/CategoryExamplesForm";
import {
    actualizarEjemploCategoriaProductoAdmin,
    actualizarNombreCategoriaProductoAdmin,
    crearEjemploCategoriaProductoAdmin,
    eliminarEjemploCategoriaProductoAdmin,
    obtenerListaProductosPorCategoriaAdmin,
} from "../../api/admin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faCheck,
    faFloppyDisk,
    faTriangleExclamation,
    faX,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import { FormsContext } from "../../../context/forms";

export default function ProductosAdminEditar() {
    const [ejemplosDeProductos, setEjemplosDeProductos] = useState([]);
    const [submitRefList, setSubmitRefList] = useState([]);
    const { state } = useLocation();
    const navigate = useNavigate();
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const submitBtnNameRef = useRef(null);
    const submitBtnDetailsRef = useRef(null);

    const formsContext = useContext(FormsContext);

    useEffect(() => {
        async function getEjemplosDeProductos() {
            const response = await obtenerListaProductosPorCategoriaAdmin(
                state.id
            );

            setEjemplosDeProductos(response || []);
        }

        getEjemplosDeProductos();
    }, [state.id]);

    function orquestrarLlamadasAPIForm() {
        if (formsContext.isAnyNotValid()) {
            return;
        }

        submitBtnNameRef.current.click();
        submitBtnDetailsRef.current.click();

        submitRefList.forEach((inputRef) => {
            inputRef?.input?.current?.click();
        });

        formsContext.cleanForms();
        navigate("/admin/productos", {
            state: {
                updateSuccessful: true,
                message: "Los cambios se han actualizado correctamente.",
            },
        });
    }

    Modal.setAppElement("#root");

    function handleShowModal() {
        if (formsContext.isAnyNotValid()) {
            return;
        }

        setModalIsOpen(!modalIsOpen);
    }

    return (
        <>
            <AdminNav navigate={navigate}></AdminNav>
            <div className="flex justify-center items-center flex-col gap-10 p-10">
                <div className="flex flex-col gap-4 justify-center items-center">
                    <CategoryNameForm
                        name={state.category}
                        id={state.id}
                        actualizarNombre={
                            actualizarNombreCategoriaProductoAdmin
                        }
                        submitBtnRef={submitBtnNameRef}
                    />
                    <CategoryDetailsForm
                        category={state}
                        submitBtnRef={submitBtnDetailsRef}
                    />
                </div>
                <div className="flex justify-center items-center max-w-7xl">
                    <CategoryExamplesForm
                        ejemplosDeProductos={ejemplosDeProductos}
                        crearEjemplo={crearEjemploCategoriaProductoAdmin}
                        actualizarEjemplo={
                            actualizarEjemploCategoriaProductoAdmin
                        }
                        eliminarEjemplo={eliminarEjemploCategoriaProductoAdmin}
                        submitRefList={submitRefList}
                        setSubmitRefList={setSubmitRefList}
                        category={state}
                        categoryId={state.id}
                    />
                </div>
                <div className="flex gap-4">
                    <Button
                        onClick={() => navigate("/admin/productos")}
                        icon={<FontAwesomeIcon icon={faArrowLeft} />}
                        text={"Regresar"}
                        className="px-5 py-2 items-center flex gap-2 cursor-pointer border-white border text-white bg-primary rounded-md text-xl"
                    />
                    <Button
                        onClick={handleShowModal}
                        icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                        text={"Guardar Cambios"}
                        className="px-8 py-2 items-center flex gap-2 cursor-pointer border-white border text-white bg-primary rounded-md text-xl"
                    />
                    <Modal
                        isOpen={modalIsOpen}
                        className={
                            "absolute bg-white border border-gray-500 rounded-sm p-8  top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2s"
                        }
                        overlayClassName={
                            "fixed top-0 right-0 bottom-0 left-0 w-full h-full bg-white/75"
                        }
                    >
                        <div className="flex flex-col gap-8 items-center">
                            <div className="text-xl font-bold flex items-start gap-2">
                                <FontAwesomeIcon
                                    icon={faTriangleExclamation}
                                    className="text-red-600 mt-1"
                                />
                                <div>
                                    ¿Esta seguro que desea guardar los cambios?
                                </div>
                            </div>
                            <div className="flex flex-col items-center justify-end gap-2">
                                <Button
                                    text={"Aceptar"}
                                    onClick={orquestrarLlamadasAPIForm}
                                    className="px-6 py-2 items-center flex gap-2 cursor-pointer border-white border text-white bg-primary rounded-md text-lg"
                                    icon={<FontAwesomeIcon icon={faCheck} />}
                                ></Button>
                                <Button
                                    text={"Cancelar"}
                                    onClick={handleShowModal}
                                    className="px-6 py-2 items-center flex gap-2 cursor-pointer border-white border text-white bg-primary rounded-md text-lg"
                                    icon={<FontAwesomeIcon icon={faX} />}
                                ></Button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    );
}
